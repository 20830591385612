/**
 * @param {object} storeInfo
 * @param {object} seoDetails
 * @return {string}
 */
export const getWebpageTitle = (storeInfo, seoDetails) => {
  // const businessTypes = storeInfo.store_businesses;
  if (seoDetails) return seoDetails.title.value;
  const title = storeInfo?.default_seo?.meta_title;
  return title || '';
  // let storeAddressCity = '';
  // if (storeInfo?.address?.visibility === 1 && storeInfo?.address?.city)
  //   storeAddressCity = `, ${storeInfo?.address?.city}`;
  // return `${storeInfo?.store_info?.name}${storeAddressCity} - ${
  //   businessTypes && businessTypes.length === 1 && businessTypes[0].business_id === 16
  //     ? 'Real Estate Property'
  //     : 'Order Online'
  // }`;
};
