import React from 'react';

const DomainExpired = props => {
  return (
    <div className="vh-100 flex justify-center items-center">
      <p className="absolute top-0 w-100 mt0 f14px black-70 h3 tr pt2 flex flex-column flex-row-ns justify-center justify-end-ns items-end items-center-ns ph3">
        Log In, If you are the owner of this website
        <a
          className="black ph14px pv8px ba br3 b--black-40 ml4 mt2 mt0-ns f6-ns f12px pa2 link"
          href="https://download.digitalshowroom.app/RequestCall"
        >
          Log In
        </a>
      </p>
      <section className="ph3">
        <h1 className="tc flex justify-center flex-column flex-row-ns items-center">
          <img
            src={props.storeInfo?.store_info?.logo_image}
            className="img h3 mr0 mr3-ns"
          />
          <lable className="f30px">{props.storeInfo?.store_info?.name}</lable>
        </h1>
        <p className="f24px fw6 tc">Hi, We are unavailable at the moment.</p>
        <p className="f22px fw3 tc">
          {' '}
          We will be back very soon. Inconvenience is regretted.
        </p>
      </section>
    </div>
  );
};

export default DomainExpired;
