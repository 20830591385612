
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[domain]",
      function () {
        return require("private-next-pages/[domain].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[domain]"])
      });
    }
  